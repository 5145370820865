:root {
  /* COLOR */
  --gray-100: #ffffff;
  --gray-200: #f5f5f5;
  --gray-300: #ececec;
  --gray-400: #afafaf;
  --gray-500: #8c8c8c;
  --gray-600: #696969;
  --gray-700: #464646;
  --gray-800: #232323;
  --gray-900: #000000;
  --red-100: #fce4e5;
  --red-200: #edb1b5;
  --red-300: #de7e84;
  --red-400: #ce4b54;
  --red-500: #bf1823;
  --red-600: #a6151f;
  --red-700: #8d121a;
  --red-800: #740e16;
  --red-900: #5b0b11;
  --blue-100: #eff3fa;
  --blue-200: #c9d6ef;
  --blue-300: #a2b8e4;
  --blue-400: #7c9bd8;
  --blue-500: #557dcd;
  --blue-600: #496fb9;
  --blue-700: #3d60a6;
  --blue-800: #315292;
  --blue-900: #25437e;
  --slate-100: #b4c6da;
  --slate-200: #9bb2cc;
  --slate-300: #829ebe;
  --slate-400: #688aaf;
  --slate-500: #4f76a1;
  --slate-600: #496d95;
  --slate-700: #446589;
  --slate-800: #3e5c7d;
  --slate-900: #385371;
  /* FONTS */
  --font-body: "Open Sans";
  --font-heading: var(--font-body);
  --weight-light: 300;
  --weight-regular: 400;
  --weight-mid: 600;
  --weight-bold: 700;
  /* SIZES */
  --base-size: 1rem;
  --size-300: calc(var(--base-size) * 0.43);
  --size-300: calc(var(--base-size) * 0.57);
  --size-300: calc(var(--base-size) * 0.57);
  --size-400: calc(var(--base-size) * 0.75);
  --size-500: var(--base-size);
  --size-600: calc(var(--base-size) * 1.33);
  --size-700: calc(var(--base-size) * 1.77);
  --size-800: calc(var(--base-size) * 2.4);
  --size-900: calc(var(--base-size) * 3.192);
  /* LEADING */
  --leading-tight: 1.2;
  --leading-mid: 1.5;
  --leading-loose: 1.7;
  /* OTHER */
  --border-radius: 0.25rem;
}

.menu__sections > * + *, .page > footer > * + *, .page__content > * + *, .page__content .page__newsBlock > * + *, .sideMenu li > * + *, .sideMenu__pages > * + *, .sideMenu__subPages > * + *, section.not-found > * + *, .home > * + *, dialog.home__alert section > * + *, form > * + *, form fieldset > * + * {
  margin-top: var(--flow-space, 1rem);
}

.menu button, .page__sectionTitle, .page__content .page__teamInfoBlock figure .page__teamInfoOverlay li span, .sideMenu__sectionTitle, .home__link a {
  text-transform: uppercase;
}

a[target=_blank] span {
  border: 0;
  clip: rect(0 0 0 0);
  height: auto;
  margin: 0;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
  white-space: nowrap;
}

.page__content figcaption, form legend {
  text-align: center;
}

.menu, .home {
  max-width: 75rem;
  margin-left: auto;
  margin-right: auto;
  padding: 0 1rem;
}

.layout[data-layout=page] .layout__header, .page__sectionTitle, .page__content, .page__subPages {
  background-color: var(--gray-100);
}

.layout[data-layout=page], .page__content .page__newsBlock .page__newsInfoTag {
  background-color: var(--gray-200);
}

::selection {
  background-color: var(--gray-900);
}

button {
  background-color: var(--slate-500);
}

button:hover {
  background-color: var(--slate-600);
}

button, ::selection, .home__link, .home__link a {
  color: var(--gray-100);
}

.sideMenu__sectionTitle, form legend {
  color: var(--gray-400);
}

.page__content figcaption {
  color: var(--gray-500);
}

.sideMenu a {
  color: var(--gray-700);
}

a, h1, .sideMenu li[data-current] > a {
  color: var(--slate-500);
}

.home__welcome em {
  color: var(--slate-700);
}

.home__welcome h2 {
  color: var(--slate-800);
}

body,
html {
  font-family: var(--font-body);
}

h1,
h2,
h3,
h4 {
  font-family: var(--font-heading);
}

.page__content figcaption {
  margin-top: var(--size-300);
}

.menu button, .home__welcome em {
  margin-bottom: var(--size-500);
}

.page__content .page__newsBlock .page__newsInfoTag {
  margin-right: var(--size-500);
}

h1,
h2,
h3,
h4, input,
select {
  line-height: var(--leading-tight);
}

body,
html {
  line-height: var(--leading-mid);
}

form .form__radio-group > div {
  line-height: var(--leading-loose);
}

input,
select, .page__content .page__tableBlock td {
  padding: var(--size-300);
}

.page__content .page__newsBlock .page__newsInfoTag, .page__content .page__tableBlock th {
  padding-left: var(--size-300);
  padding-right: var(--size-300);
}

.page__content .page__newsBlock .page__newsInfo {
  padding-top: var(--size-300);
  padding-bottom: var(--size-300);
}

.page__sectionTitle, .home, .home__link {
  padding: var(--size-500);
}

.sideMenu {
  padding-right: var(--size-500);
}

.page__subPages ul {
  padding-top: var(--size-500);
}

.footer {
  padding-top: var(--size-500);
  padding-bottom: var(--size-500);
}

.sideMenu {
  padding-left: var(--size-800);
}

.sideMenu {
  padding-top: var(--size-800);
}

.home__welcome {
  padding-top: var(--size-800);
  padding-bottom: var(--size-800);
}

body,
html, h4 {
  font-size: var(--size-500);
}

h3, .home__welcome a, form legend {
  font-size: var(--size-600);
}

h2, .page > footer a {
  font-size: var(--size-700);
}

h1 {
  font-size: var(--size-800);
}

form legend {
  font-weight: var(--weight-light);
}

.sideMenu a, form .form__control[data-type=boolean] label {
  font-weight: var(--weight-regular);
}

button, a, .sideMenu li[data-current] > a {
  font-weight: var(--weight-mid);
}

h1,
h2, .menu button, .page__sectionTitle {
  font-weight: var(--weight-bold);
}

/* open-sans-300 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 300;
  src: url("/fonts/open-sans-v18-latin-300.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/open-sans-v18-latin-300.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans-v18-latin-300.woff2") format("woff2"), url("/fonts/open-sans-v18-latin-300.woff") format("woff"), url("/fonts/open-sans-v18-latin-300.ttf") format("truetype"), url("/fonts/open-sans-v18-latin-300.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-regular - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 400;
  src: url("/fonts/open-sans-v18-latin-regular.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/open-sans-v18-latin-regular.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans-v18-latin-regular.woff2") format("woff2"), url("/fonts/open-sans-v18-latin-regular.woff") format("woff"), url("/fonts/open-sans-v18-latin-regular.ttf") format("truetype"), url("/fonts/open-sans-v18-latin-regular.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-italic - latin */
@font-face {
  font-family: "Open Sans";
  font-style: italic;
  font-weight: 400;
  src: url("/fonts/open-sans-v18-latin-italic.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/open-sans-v18-latin-italic.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans-v18-latin-italic.woff2") format("woff2"), url("/fonts/open-sans-v18-latin-italic.woff") format("woff"), url("/fonts/open-sans-v18-latin-italic.ttf") format("truetype"), url("/fonts/open-sans-v18-latin-italic.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-600 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 600;
  src: url("/fonts/open-sans-v18-latin-600.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/open-sans-v18-latin-600.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans-v18-latin-600.woff2") format("woff2"), url("/fonts/open-sans-v18-latin-600.woff") format("woff"), url("/fonts/open-sans-v18-latin-600.ttf") format("truetype"), url("/fonts/open-sans-v18-latin-600.svg#OpenSans") format("svg"); /* Legacy iOS */
}
/* open-sans-700 - latin */
@font-face {
  font-family: "Open Sans";
  font-style: normal;
  font-weight: 700;
  src: url("/fonts/open-sans-v18-latin-700.eot"); /* IE9 Compat Modes */
  src: local(""), url("/fonts/open-sans-v18-latin-700.eot?#iefix") format("embedded-opentype"), url("/fonts/open-sans-v18-latin-700.woff2") format("woff2"), url("/fonts/open-sans-v18-latin-700.woff") format("woff"), url("/fonts/open-sans-v18-latin-700.ttf") format("truetype"), url("/fonts/open-sans-v18-latin-700.svg#OpenSans") format("svg"); /* Legacy iOS */
}
form .form__radio-group > div, form .form__control[data-type=boolean] {
  align-items: baseline;
  column-gap: var(--size-300);
  display: grid;
  grid-template: "input label" auto ". error" auto/max-content 1fr;
}
form .form__radio-group > div input, form .form__control[data-type=boolean] input {
  grid-area: input;
  margin-top: 0.4rem;
  width: auto;
}
form .form__radio-group > div label, form .form__control[data-type=boolean] label {
  grid-area: label;
}
form .form__radio-group > div .form__error, form .form__control[data-type=boolean] .form__error {
  grid-area: error;
}

form .form__alert {
  padding: var(--size-500);
  border-radius: var(--border-radius);
  display: none;
}
form .form__alert[data-state=error] {
  background-color: var(--red-100);
  border: 1px solid var(--red-500);
}
form .form__alert[data-state=success] {
  background-color: var(--blue-100);
  border: 1px solid var(--blue-500);
}
form fieldset {
  --flow-space: var(--size-800);
  --gutter: var(--size-200);
  border: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
form fieldset * {
  --flow-space: var(--size-500);
}
form .form__control[data-layout="50%"] {
  width: calc(50% - var(--gutter) / 2);
}
form .form__control[data-layout="100%"] {
  width: 100%;
}
form .form__control input,
form .form__control > label,
form .form__control select {
  width: 100%;
}
form .form__control input[aria-invalid=true],
form .form__control > label[aria-invalid=true],
form .form__control select[aria-invalid=true] {
  border-color: red;
}
form .form__control > label {
  display: block;
  font-weight: bold;
}
form .form__error {
  color: red;
}
form legend {
  border-bottom: 1px solid currentColor;
  width: 100%;
}

@media (min-width: 1025px) {
  .home {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
.home__tilesTop, .home__tilesMid, .home__tilesBottom {
  display: grid;
  gap: var(--size-500);
  grid-template-columns: repeat(2, minmax(0, 1fr));
}
@media (min-width: 1025px) {
  .home__tilesTop, .home__tilesMid, .home__tilesBottom {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
}
@media (min-width: 1025px) {
  .home__tilesMid {
    grid-template-areas: "portrait portrait welcome welcome ." "portrait portrait welcome welcome .";
  }
  .home__tilesBottom {
    grid-template-areas: "tile tile tile image image";
  }
}
.home__imageBottom {
  grid-column: span 1/-1;
}
@media (min-width: 1025px) {
  .home__imageBottom {
    grid-area: image;
  }
}
.home__imageTop {
  display: none;
}
@media (min-width: 1025px) {
  .home__imageTop {
    display: block;
    grid-column: span 2;
  }
}
.home__logo {
  grid-column: span 2;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.home__logo h1 {
  display: flex;
  flex-direction: column;
  font-size: var(--size-900);
}
.home__logo h1 div:first-child {
  font-weight: normal;
  font-size: var(--size-700);
  border-bottom: 1px solid var(--slate-500);
  padding: var(--size-300);
}
.home__logo svg {
  width: 80%;
  margin-bottom: var(--size-500);
}
.home__logo svg path {
  fill: var(--slate-500);
}
@media (min-width: 1025px) {
  .home__logo {
    grid-row: span 2;
  }
}
.home__portrait {
  grid-row: span 2;
}
@media (min-width: 1025px) {
  .home__portrait {
    grid-area: portrait;
  }
}
.home__link {
  display: grid;
  grid-template-areas: "stack";
}
.home__link::before, .home__link > div {
  grid-area: stack;
}
.home__link::before {
  content: "";
  padding-bottom: 100%;
}
.home__welcome {
  grid-column: span 2;
  text-align: center;
}
@media (min-width: 1025px) {
  .home__welcome {
    grid-area: welcome;
    display: grid;
    place-items: center;
    align-content: center;
  }
}

dialog.home__alert {
  border: none;
  background: none;
}
dialog.home__alert .home__alertContent {
  display: grid;
  grid-template-columns: 3rem 1fr 3rem;
  background-color: #fffbe6;
  border: 1px solid #ffefa0;
  border-radius: 0.5em;
  max-width: 700px;
  padding: 1em;
  max-height: 90vh;
  overflow: scroll;
}
dialog.home__alert .home__alertIcon {
  display: block;
  border: 3px solid #ffe45b;
  width: 1.325em;
  height: 1.325em;
  text-align: center;
  border-radius: 50%;
  position: relative;
  margin-right: 1em;
  box-sizing: content-box;
}
dialog.home__alert .home__alertIcon::before {
  content: "!";
  color: #ffe45b;
  position: relative;
  top: -5px;
  font-size: 1.25em;
  font-weight: bold;
}
dialog.home__alert button {
  grid-column: 2;
  margin-top: 1rem;
  justify-self: start;
}
.home__imageTile {
  position: relative;
}
.home__imageTile picture {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.home__imageTile img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.home__imageTile--contain img {
  object-fit: contain;
}

.home__link {
  border: 2px solid;
  word-wrap: anywhere;
}
.home__link[data-link=contactinfo] {
  display: grid;
  text-align: right;
}
.home__link[data-link=contactinfo] ul {
  list-style: none;
  margin: 0;
  padding: 0;
}
.home__link[data-link=contactinfo] li {
  padding: 0.2rem 0;
}
.home__link[data-link=contactinfo] li:not(:first-child) {
  display: none;
}
@media (min-width: 1025px) {
  .home__link[data-link=contactinfo] {
    place-items: center flex-start;
    text-align: left;
  }
  .home__link[data-link=contactinfo] li:not(:first-child) {
    display: block;
  }
}
.home__link[data-link=pagelink] {
  position: relative;
}
.home__link[data-link=pagelink] a {
  flex: 1;
  text-align: right;
  align-self: flex-end;
}
.home__link[data-link=pagelink] a::after {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
@media (min-width: 1025px) {
  .home__link[data-link=pagelink] a {
    max-width: 75%;
  }
}
.home__link[data-link=pagelink] p {
  display: none;
}
@media (min-width: 1025px) {
  .home__link[data-link=pagelink] p {
    display: block;
  }
}
.home__link[data-link=pagelink] > div {
  display: flex;
  flex-direction: column;
}
.home__tilesTop .home__link:nth-of-type(1), .home__tilesMid .home__link:nth-of-type(2) {
  background-color: #1f386a;
  border-color: #1f386a;
}
.home__tilesTop .home__link:nth-of-type(2), .home__tilesBottom .home__link:nth-of-type(2), .home__tilesBottom .home__link:nth-of-type(3) {
  background-color: #ed6a15;
  border-color: #ed6a15;
}
.home__tilesTop .home__link:nth-of-type(3), .home__tilesBottom .home__link:nth-of-type(1) {
  background-color: #009245;
  border-color: #009245;
}
.home__tilesTop .home__link:nth-of-type(4) {
  background-color: var(--slate-500);
  border-color: var(--slate-500);
}
.home__tilesMid .home__link:nth-of-type(1) {
  background-color: #d81e6a;
  border-color: #d81e6a;
}
.home__tilesBottom .home__link:nth-of-type(2), .home__tilesBottom .home__link:nth-of-type(3) {
  background: none;
  color: inherit;
}
.home__tilesBottom .home__link:nth-of-type(2) a, .home__tilesBottom .home__link:nth-of-type(3) a {
  color: #ed6a15;
}

.home__welcome em {
  display: block;
}
.layout {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
}
.layout[data-layout=page] .layout__header {
  position: sticky;
  top: 0;
  z-index: 10;
}

.page {
  --page-content-width: 60ch;
  --page-padding-min: var(--size-500);
  --page-padding-max: 4rem;
  --page-width: calc(var(--page-content-width) + 2 * var(--page-padding-max));
  flex: 1;
  display: grid;
  grid-template: "header   header  header " max-content "sidemenu content contact" 1fr "    .    footer     .   "/minmax(var(--page-padding-min), 1fr) minmax(auto, var(--page-width)) minmax(var(--page-padding-min), 1fr);
}
.page > footer {
  --offset: 0.5rem;
  background-color: var(--gray-300);
  grid-area: footer;
  padding: var(--size-800) var(--page-padding-min);
  text-align: center;
  background-clip: padding-box;
  border: var(--offset) solid var(--gray-100);
  border-bottom: none;
  border-image: linear-gradient(to bottom, var(--gray-100) 50%, transparent 50%) 1;
  border-top: none;
}
.page > footer em {
  display: block;
}
@media (min-width: 1281px) {
  .page > footer {
    grid-area: contact;
    border: none;
    background: none;
  }
}
.page > header {
  display: grid;
  grid-template: "section-image section-image section-image" 7rem "      .       section-title       .      " 3rem/var(--page-padding-min) 1fr var(--page-padding-min);
  justify-items: center;
  grid-area: header;
}
.page > header .page__sectionImage {
  justify-self: stretch;
  grid-area: section-image;
}
.page > header .page__sectionTitle {
  grid-area: section-title;
}
.page > section {
  display: flex;
  flex-direction: column;
  grid-area: content;
  max-width: var(--page-width);
}
.page .sideMenu {
  grid-area: sidemenu;
}

.page__sectionImage {
  margin-bottom: -2rem;
}
.page__sectionImage picture, .page__sectionImage img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.page__sectionTitle {
  max-width: var(--page-width);
  width: 100%;
}
@media (min-width: 1025px) {
  .page__sectionTitle {
    color: transparent;
  }
}

.page__content {
  --flow-space: var(--size-600);
  padding-left: var(--page-padding-min);
  padding-right: var(--page-padding-min);
}
@media (min-width: 1025px) {
  .page__content {
    padding-left: var(--page-padding-max);
    padding-right: var(--page-padding-max);
  }
}
.page__content .page__figureBlock,
.page__content .page__figureBlock + * {
  --flow-space: var(--size-800);
}
.page__content figcaption {
  font-style: italic;
}
.page__content .page__fileBlock {
  display: block;
}
.page__content .page__fileBlock::before {
  content: url("/img/file.svg");
  width: 1em;
  height: 1em;
  display: inline-block;
  display: inline flow-root;
  margin-right: 0.5em;
}
.page__content .page__fileBlock::after {
  display: none;
}
.page__content :is(h2, h3, h4) + * {
  --flow-space: var(--size-400);
}
.page__content :is(h2, h3) {
  --flow-space: var(--size-800);
}
.page__content h4 {
  --flow-space: var(--size-600);
}
.page__content .page__mapBlock {
  height: 25rem;
  width: 100%;
}
.page__content .page__newsBlock article {
  --flow-space: var(--size-800);
}
.page__content .page__newsBlock .page__newsInfo {
  display: flex;
}
.page__content .page__newsBlock .page__newsInfoDate {
  font-style: italic;
}
.page__content .page__newsBlock .page__newsInfoTag {
  border-radius: var(--border-radius);
}
.page__content .page__tableBlock {
  border-collapse: collapse;
  width: 100%;
}
.page__content .page__tableBlock td {
  text-align: left;
}
.page__content .page__tableBlock td {
  border: 1px solid var(--gray-200);
}
.page__content .page__tableBlock th {
  text-align: left;
}
.page__content .page__teamInfoBlock figure {
  display: grid;
  grid-template-areas: "stack";
}
.page__content .page__teamInfoBlock figure > * {
  grid-area: stack;
}
.page__content .page__teamInfoBlock figure .page__teamInfoOverlay {
  background: linear-gradient(to bottom, rgba(0, 0, 0, 0) 40%, rgb(0, 0, 0) 100%);
  color: white;
  display: flex;
  align-items: flex-end;
  margin: 0;
  padding: 0;
  list-style: none;
}
.page__content .page__teamInfoBlock figure .page__teamInfoOverlay li {
  flex: 1;
}
.page__content .page__teamInfoBlock figure .page__teamInfoOverlay li:nth-child(2) {
  text-align: right;
}
.page__content .page__teamInfoBlock figure .page__teamInfoOverlay li span {
  display: block;
}
.page__content .page__teamInfoBlock figure .page__teamInfoOverlay li a {
  color: currentColor;
}
.page__content .page__teamInfoBlock .page__teamInfoDetails {
  display: flex;
}

.page__subPages {
  padding: var(--page-padding-min);
  flex: 1;
}
@media (min-width: 1025px) {
  .page__subPages {
    padding-left: var(--page-padding-max);
    padding-right: var(--page-padding-max);
  }
}
.page__subPages ul {
  --gap: calc(var(--size-500) / 2);
  border-top: 1px solid var(--gray-300);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  margin: 0 calc(-1 * var(--gap));
}
.page__subPages ul > * {
  margin: 0 var(--gap);
}
.page__subPages a,
.page__subPages li {
  padding: 0.2rem 0;
  text-align: center;
  text-decoration: underline;
}

.sideMenu {
  display: none;
}
@media (min-width: 1281px) {
  .sideMenu {
    display: block;
  }
}
.sideMenu li::before {
  content: "";
  background-image: url("/img/caret-right.svg");
  background-size: contain;
  width: var(--size-400);
  height: var(--size-400);
  margin-right: 0.1em;
  display: inline-block;
  display: inline flow-root;
}
.sideMenu__pages {
  --flow-space: var(--size-300);
  margin-top: var(--flow-space);
}
.sideMenu__pages > li[data-current=child][data-has-subpages]::before {
  background-image: url("/img/caret-down.svg");
}
.sideMenu__subPages {
  --flow-space: var(--size-200);
  margin-left: var(--size-600);
}

section.not-found {
  display: grid;
  place-content: center;
  background: white;
  text-align: center;
}

.footer {
  background: white;
  bottom: 0;
  display: flex;
  justify-content: space-around;
  position: sticky;
  align-items: center;
}
.footer *:not(:last-child) {
  display: none;
}
@media (min-width: 1025px) {
  .footer *:not(:last-child) {
    display: block;
  }
}

.menu {
  --menu-padding: var(--size-500);
  padding: var(--menu-padding);
  padding-bottom: 0;
  display: flex;
  flex-direction: column;
}
.menu button {
  width: max-content;
  align-self: flex-end;
}
.menu__sections {
  transform: scaleY(0);
  transform-origin: top;
  transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  visibility: hidden;
  width: 100vw;
  margin-left: calc(-1 * var(--menu-padding));
  flex: 1;
  padding: var(--menu-padding);
  position: absolute;
  top: 100%;
  overflow: scroll;
}
.menu__sections > li {
  opacity: 0;
  margin: 0;
}
@media (max-width: 1023px) {
  .menu__sections > li:last-child {
    margin-bottom: var(--menu-padding);
  }
}
.menu[aria-expanded=true] .menu__sections {
  transform: scaleY(1);
  visibility: visible;
  position: static;
}
.menu[aria-expanded=true] .menu__sections > li {
  opacity: 1;
  transition: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 1) 0.3s;
}
.menu__pages {
  margin-left: var(--menu-padding);
}
.menu__subPages {
  display: none;
}
.menu[aria-expanded=true] {
  height: 100vh;
}

@media (min-width: 1025px) {
  .menu {
    display: block;
  }
  .menu button {
    display: none;
  }
  .menu__sections {
    transform: none;
    width: auto;
    margin-left: 0;
    padding: 0;
    overflow: visible;
    visibility: visible;
    display: flex;
    justify-content: space-around;
    position: relative;
  }
  .menu__sections > li {
    opacity: 1;
  }
  .menu__pages {
    transform: scaleY(0);
    transform-origin: top;
    transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1);
    visibility: hidden;
    position: absolute;
    top: 100%;
    z-index: 10;
    width: 100vw;
    left: calc(50% - 50vw);
    grid-template-columns: repeat(2, 1fr);
    justify-items: end;
    margin: 0;
    padding: var(--menu-padding) 0;
  }
  .menu__pages > li {
    opacity: 0;
    grid-column: 1;
    position: relative;
  }
  .menu__pages > li a, .menu__pages > li::after {
    position: relative;
    z-index: 10;
  }
  .menu__sections li:hover .menu__pages {
    transform: scaleY(1);
    visibility: visible;
    display: grid;
  }
  .menu__sections li:hover .menu__pages > li {
    opacity: 1;
    transition: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 1) 0.3s;
  }
  .menu__subPages {
    display: block;
    position: absolute;
    left: 100%;
    top: 0;
    width: 50vw;
    padding-bottom: var(--menu-padding);
    opacity: 0;
    transition: opacity 0.3s cubic-bezier(0.5, 0, 0.5, 1);
    visibility: hidden;
  }
  .menu__subPages::before {
    content: "";
    position: absolute;
    top: 0;
    height: 100%;
    z-index: -1;
    width: 100vw;
    left: -50vw;
    transform-origin: top;
    transform: scaleY(0);
    transition: transform 0.3s cubic-bezier(0.5, 0, 0.5, 1);
  }
  .menu__pages li:hover .menu__subPages {
    visibility: visible;
    opacity: 1;
  }
  .menu__pages li:hover .menu__subPages::before {
    transform: scaleY(1);
  }
}
.menu button {
  background: none;
  border: none;
  color: inherit;
}

.menu__sections {
  background-color: var(--slate-500);
}
.menu__sections a {
  color: var(--gray-100);
  display: block;
  display: flow-root;
  padding: 0.2rem 0;
}
@media (min-width: 1025px) {
  .menu__sections {
    background: none;
  }
  .menu__sections a {
    display: inline;
    padding: 0;
  }
  .menu__sections > li > a {
    color: inherit;
    padding: 0 var(--size-800);
  }
}

.menu__pages a {
  font-weight: var(--weight-regular);
}
@media (min-width: 1025px) {
  .menu__pages {
    background-color: var(--slate-500);
  }
  .menu__pages > li::after {
    content: "";
    display: inline-block;
    display: inline flow-root;
    width: var(--size-500);
    height: var(--size-500);
    vertical-align: middle;
  }
  .menu__pages > li[data-has-subpages]:hover::after {
    background: url("/img/caret-right.svg") no-repeat;
    background-size: cover;
  }
}

.menu__subPages::before {
  background-color: var(--slate-500);
}

button {
  appearance: none;
  border: none;
  border-radius: var(--border-radius);
  padding: 0.6rem 1rem;
  cursor: pointer;
  transition: all 0.2s;
}
a {
  text-decoration: none;
}
a[target=_blank]::after {
  content: url(/img/external-link.svg);
  display: inline-block;
  margin: 0 0.1em;
  width: var(--size-500);
  height: var(--size-500);
}
a:hover, a:active {
  text-decoration: underline;
}

h1 {
  max-width: 18ch;
  word-wrap: anywhere;
}

h2,
h3,
h4 {
  max-width: 30ch;
}

input,
select {
  border: 1px solid;
  border-color: var(--gray-400);
  border-radius: var(--border-radius);
  margin: 0;
  transition: all 0.2s;
}
input:focus,
select:focus {
  border-color: var(--blue-500);
  box-shadow: var(--blue-500) 0 0 0 1px;
  outline: none;
}
input:hover,
select:hover {
  border-color: var(--gray-500);
}

nav ul {
  list-style: none;
  padding: 0;
  margin: 0;
}

img,
picture {
  height: auto;
}

:focus {
  outline: 1px solid var(--blue-500);
}